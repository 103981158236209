/* eslint-disable */
import $ from "jquery";

$(function() {
    let moveTimer = 0;
    let hideTimer = 0;
    $('.js-has-sub').hover(function () {
        const menu = $(this).children('.sub-menu');
        $(this).on('mousemove', () =>{
            clearTimeout(moveTimer);
            clearTimeout(hideTimer);
            moveTimer = setTimeout(() =>{
                menu.addClass('slide-down')
            }, 50);
        });
    // ホバーが外れている時
    },function(){
        const menu = $(this).children('.sub-menu');
        clearTimeout(moveTimer);
        hideTimer = setTimeout(() =>{
            menu.removeClass('slide-down')
        }, 500);
    });
});
