/* eslint-disable */
import $ from "jquery";

$(function() {
    $(window).scroll(function () {
        const wHeight = $(window).height();
        const scrollAmount = $(window).scrollTop() -100;
        // 下からフェードイン
        $('.js-fadein').each(function () {
            const targetPosition = $(this).offset().top;
            if(scrollAmount > targetPosition - wHeight + 60) {
                $(this).addClass("fadeInDown");
            }
        });
        // 左からフェードイン
        $('.js-fadein-left').each(function () {
            const targetPosition = $(this).offset().top;
            if(scrollAmount > targetPosition - wHeight + 60) {
                $(this).addClass("fadeInLeft");
            }
        });
        // 右からフェードイン
        $('.js-fadein-right').each(function () {
            const targetPosition = $(this).offset().top;
            if(scrollAmount > targetPosition - wHeight + 60) {
                $(this).addClass("fadeInRight");
            }
        });
    });

    // ファーストビュー専用
    setTimeout(function(){
        $(".js-fadein-left200").addClass("fadeInFirstViewLeft");
    },200);
    setTimeout(function(){
        $(".js-fadein-left400").addClass("fadeInFirstViewLeft");
    },400);
    setTimeout(function(){
        $(".js-fadein-right200").addClass("fadeInFirstViewRight");
    },200);
    setTimeout(function(){
        $(".js-fadein-right400").addClass("fadeInFirstViewRight");
    },400);
});
