/* eslint-disable */
import $ from "jquery";

$(function() {
    $(".js-toggle-nav").on("click", function () {
        $(this).toggleClass("is-open");
        $(".l-header").toggleClass("is-open");
        $(".l-header__nav").toggleClass("is-open");
        $(".c-nav").toggleClass("is-open");
        $("body").toggleClass("is-open");
    });
    $(".c-nav__item a").on("click", () => {
        $(".js-toggle-nav").removeClass("is-open");
        $(".l-header").removeClass("is-open");
        $(".l-header__nav").removeClass("is-open");
        $(".c-nav").removeClass("is-open");
        $("body").removeClass("is-open");
    });
    // リサイズされたらリセット
    $(window).on("resize", () => {
        $(".js-toggle-nav").removeClass("is-open");
        $(".l-header").removeClass("is-open");
        $(".l-header__nav").removeClass("is-open");
        $(".c-nav").removeClass("is-open");
        $("body").removeClass("is-open");
    });
});
