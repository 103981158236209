/* eslint-disable */
import $ from "jquery";

$(function() {
    // パラメータ取得
    function getParam(name, url) {
        if (!url) url = window.location.href;
        name = name.replace(/[\[\]]/g, "\\$&");
        let regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
        results = regex.exec(url);
        if (!results) return null;
        if (!results[2]) return '';
        return decodeURIComponent(results[2].replace(/\+/g, " "));
    }
    // ページ読み込み時のタブ切り替え
    let tabPram = ['newly-built-house', 'renovation', 'commercial-architecture', 'interior-design', 'reform'];
    let pram = getParam('active-tab');
    if (pram && $.inArray(pram, tabPram) !== -1) {
        $('.js-tab-contents,.js-tab-switch').removeClass('is-active');
        $('[data-tab="' + pram + '"]').addClass('is-active');
    }
    // ロード後のタブ切り替え
    $('.js-tab-switch').on('click', function() {
        let dataPram = $(this).data('tab');
        $('.js-tab-contents,.js-tab-switch').removeClass('is-active');
        $('[data-tab="' + dataPram + '"]').addClass('is-active');
    });
});
