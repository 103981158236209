/* eslint-disable */
import $ from "jquery";

$(function() {
    if($('.js-swiper').length){
        const swiper = new Swiper(".js-swiper", {
            autoplay: {
                delay: 5000,
                stopOnLastSlide: false,
                disableOnInteraction: false,
                reverseDirection: false
            },
            effect: 'slide',
            loop: true,
            mousewheel: true,
        });
    }
});
