/* eslint-disable */
import '../scss/style.scss';

import $ from 'jquery';
import './lib/nav';
import './lib/subMenu';
import './lib/swiper';
import './lib/tab';
import './lib/animation';

$(function() {
});

window.onload = function () {
};
